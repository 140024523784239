.hero-section {
  height: 100vh;
  width: 100%;
}

.hero-content {
  height: 100vh;
}

.hero-bg {
  background-position: bottom right;
}

@media screen and (min-width: 992px) {
  .hero-bg {
    background-position: center left;
  }
}
