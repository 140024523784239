.brandSwiper .swiper-pagination {
  /* bottom: -40% !important; */
  z-index: 1000;
  margin-top: 2rem;
  display: block;
  position: relative;
}

.brandSwiper {
  overflow-y: visible !important;
}

.brandSwiper .swiper-pagination-bullet {
  width: 50px;
  height: 7px;
  border-radius: 11px;
  background-color: #777 !important;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
}

.brandSwiper .swiper-pagination-bullet-active {
  background-color: #fff !important;
}

@media (min-width: 1140px) {
  .brandSwiper .swiper-pagination-bullet {
    width: 50px;
    height: 8px;
    border-radius: 11px;
    background-color: #777 !important;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
  }
}
