.parent {
  padding-left: 1.5rem;
  padding-right: 1rem;
  overflow: visible;
}
.catswipperslider .swiper-scrollbar {
  position: relative !important;
  margin-top: 1.8rem;
  z-index: 100;
  cursor: grab;
  height: 8px;

  /* margin-inline: auto; */
  width: 96%;
  overflow: hidden;
}

.catswipperslider .swiper-scrollbar-drag {
  cursor: grab;
}

@media (min-width: 768px) {
  .parent {
    padding-inline: 6.1rem;
  }
}
@media (min-width: 1140px) {
  .parent {
    padding-inline: 0;

    padding-left: 8rem;
  }
}
@media (min-width: 1440px) {
  .parent {
    padding-left: 15rem;
  }
}
