.logoSlider .swiper-pagination {
  margin-top: 1rem;
  display: block;
  position: relative;
}

.logoSlider .swiper-wrapper {
  /* justify-content: center !important; */
  /* align-items: center !important; */
  column-gap: 1rem;
  margin-top: 1rem;
}
.logoSlider .swiper-slide {
  margin-right: 0 !important;
}

.logoSlider .swiper-pagination-bullet {
  width: 30px;
  height: 6px;
  border-radius: 11px;
  background-color: #777 !important;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
}

.logoSlider .swiper-pagination-bullet-active {
  background-color: #fff !important;
}

@media screen and (min-width: 992px) {
  .logoSlider .swiper-pagination {
    display: none;
  }
}
