@media screen and (min-width: 992px) {
  .pin-spacer {
    height: 100vh;
    display: flex;
    align-items: center;
  }
  .slider-container {
    height: 100vh;

    display: flex;
    gap: 4rem;
    align-items: center;
    flex-wrap: nowrap;
  }

  .panel {
    min-width: 100% !important;
    height: 60vh;

    display: flex;
    align-items: center;
  }

  .panel img {
    height: 60vh;
    width: 100%;
    object-fit: cover;
  }
}

@media screen and (min-width: 1440px) {
  .slider-container {
    gap: 5.8rem;
  }
}

/* slider */
.featueSlider .swiper-pagination {
  position: relative;
  margin-top: 2rem;
}

.featueSlider .swiper-pagination-bullet {
  background-color: #555 !important;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);

  width: 10px;
  height: 10px;
}
