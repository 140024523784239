@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow-y: scroll;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

body {
  overflow-y: visible;
  position: relative;
  overflow-x: hidden;
}
html,
body {
  margin: 0;
}

.list-style::marker {
  color: blue;
}

.image-rounded {
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
  border-bottom-left-radius: 60px;
}
