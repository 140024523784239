@media screen and (min-width: 992px) {
  .pin-spacer {
    height: 100vh;

    display: flex;
    min-width: 100vw !important;
    align-items: center;
  }
  .slider-container {
    height: 100vh;

    min-width: 100vw !important;

    display: flex;
    gap: 0rem;
    flex-wrap: nowrap;
  }

  .panel {
    min-width: 100vw !important;
    height: 100vh;

    display: flex;
    align-items: center;
  }
}

.panel-background {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
}
